/* TweetCard.css */
.tweet-card {
  border: 1px solid #ddd;
  padding: 15px;
  margin: 15px;
  border-radius: 10px;
  width: 500px;
  background-color: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.tweet-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.tweet-user-info h3 {
  margin: 0;
  font-size: 18px;
}

.tweet-user-info p {
  margin: 0;
  color: #555;
}

.tweet-body {
  margin-bottom: 10px;
}

.tweet-stats span {
  margin-right: 15px;
  font-size: 14px;
  color: #888;
}
