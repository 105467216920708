.prompt-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.prompt-input label {
  margin-bottom: 0.5rem;
}

.prompt-input textarea {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1.5;
  resize: vertical;
}

.prompt-input textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}